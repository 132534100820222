var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pb-5"},[_c('back-button')],1),_c('page-heading',{attrs:{"hideTVButton":"","slug":"questions","placeholder":_vm.$t('views.presenter.session.questionsSettingsPlaceholderHeader'),"currentSession":_vm.currentSession,"showButtonField":true,"buttonPlaceholder":_vm.$t('views.presenter.session.questionsSettingsBtnPlaceHolder')}}),_c('nav',{staticClass:"mt-8 flex w-1/2 justify-between",attrs:{"aria-label":"Tabs"}},[_c('button',{class:[
        !_vm.currentSession.hideAllIncomingQuestions
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":!_vm.currentSession.hideAllIncomingQuestions ? 'page' : undefined},on:{"click":function($event){return _vm.toggleQuestionsVisibilitySetting(false)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.questionsSettingsShowQuestions'))+" ")]),_c('button',{class:[
        _vm.currentSession.hideAllIncomingQuestions
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":_vm.currentSession.hideAllIncomingQuestions ? 'page' : undefined},on:{"click":function($event){return _vm.toggleQuestionsVisibilitySetting(true)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.questionsSettingsHideQuestions'))+" ")])]),_c('nav',{staticClass:"mt-8 flex w-1/2 justify-between",attrs:{"aria-label":"Tabs"}},[_c('button',{class:[
        _vm.currentSession.showQuestionsUserNameInput
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":_vm.currentSession.showQuestionsUserNameInput ? 'page' : undefined},on:{"click":function($event){return _vm.toggleQuestionsShowUserNameInputSetting(true)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.questionsSettingsName'))+" ")]),_c('button',{class:[
        !_vm.currentSession.showQuestionsUserNameInput
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":!_vm.currentSession.showQuestionsUserNameInput ? 'page' : undefined},on:{"click":function($event){return _vm.toggleQuestionsShowUserNameInputSetting(false)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.questionsSettingsNoName'))+" ")])]),_c('nav',{staticClass:"mt-8 flex w-1/2 justify-between",attrs:{"aria-label":"Tabs"}},[_c('button',{class:[
        !_vm.currentSession.hideQuestionLikes
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":!_vm.currentSession.hideQuestionLikes ? 'page' : undefined},on:{"click":function($event){return _vm.toggleQuestionsLikesSetting(false)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.questionsSettingsShowLikes'))+" ")]),_c('button',{class:[
        _vm.currentSession.hideQuestionLikes
          ? 'bg-primary bg-opacity-10 text-primary'
          : 'text-gray-500 hover:text-gray-700',
        'px-3 py-2 font-medium text-sm rounded-md focus:outline-none focus:border-none' ],attrs:{"aria-current":_vm.currentSession.hideQuestionLikes ? 'page' : undefined},on:{"click":function($event){return _vm.toggleQuestionsLikesSetting(true)}}},[_vm._v(" "+_vm._s(_vm.$t('views.presenter.session.questionsSettingsHideLikes'))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }