










































































































import Vue from 'vue';
import { mapGetters } from 'vuex';
import PageHeading from '@/components/Presenter/PageHeading.vue';
import BackButton from '@/components/Presenter/BackButton.vue';

export default Vue.extend({
  components: { PageHeading, BackButton },

  data() {
    return {
      drag: false,
      sortedQuestions: [],
    };
  },
  computed: {
    ...mapGetters({
      currentSession: 'presenter/getCurrentSession',
    }),
  },
  methods: {
    toggleQuestionsVisibilitySetting(value: boolean) {
      this.$store.dispatch('questions/toggleQuestionsVisibilitySetting', value);
    },
    toggleQuestionsShowUserNameInputSetting(value: boolean) {
      this.$store.dispatch(
        'questions/toggleQuestionsShowUserNameInputSetting',
        value
      );
    },
    toggleQuestionsLikesSetting(value: boolean) {
      this.$store.dispatch('questions/toggleQuestionsLikesSetting', value);
    },
  },
});
